import { Modal, Input, Select, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
const { Option } = Select;
const { Text } = Typography;
const CompareSite = ({ visible, setVisible, loading, handleOk }) => {
  const [domain1, setDomain1] = useState("");
  const [protocol1, setProtocol1] = useState("https://");
  const [domain2, setDomain2] = useState("");
  const [protocol2, setProtocol2] = useState("https://");
  const [submitted, setSubmitted] = useState("");
  const urlReg=/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  useEffect(() => {
    if (!visible) {
      setSubmitted(false);
    }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
  };
  const reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;

  const isValid1 = useMemo(() => {
    return reg.test(domain1);
  }, [domain1]);
  const isValid2 = useMemo(() => {
    return reg.test(domain2);
  }, [domain2]);
  const structureUrl1 = (e) => {
    
    try {
      setTimeout(() => {
        if( e.target.value.match(urlReg)){
        const url = new URL(e.target.value);
        setProtocol1(`${url.protocol}//`);
        setDomain1(url.hostname);
        }
      }, 0);
    } catch (e) {
      // console.log(e);
    }
  };
  const structureUrl2 = (e) => {
    try {
      setTimeout(() => {
        if( e.target.value.match(urlReg)){
        const url = new URL(e.target.value);

        setProtocol2(`${url.protocol}//`);
        setDomain2(url.hostname);
        }
      }, 0);
    } catch (e) {
      // console.log(e);
    }
  };
  return (
    <>
      <Modal
        centered
        title='Enter Domain Names'
        visible={visible}
        onOk={() => {
          setSubmitted(true);
          if (isValid1) {
            handleOk([protocol1 + domain1,protocol2 + domain2]);
          }
        }}
        confirmLoading={loading}
        onCancel={handleCancel}
      >
        <center>
          {/* <label>Enter Domain Name</label> */}
          <Input
            addonBefore={
              <Select
                value={protocol1}
                className='select-before'
                onChange={(v) => setProtocol1(v)}
                >
                <Option value='http://'>http://</Option>
                <Option value='https://'>https://</Option>
              </Select>
            }
            addonAfter='/sitemap.xml'
            value={domain1}
            onPaste={structureUrl1}
            onChange={(e) => setDomain1(e.target.value)}
          />
          {submitted && !isValid1 && (
            <Text type='danger'>Please enter valid domain name</Text>
          )}
        </center>
        <center>
            <span>VS</span>
        </center>
        <center>
          {/* <label>Enter Domain Name</label> */}
          <Input
            addonBefore={
              <Select
                value={protocol2}
                className='select-before'
                onChange={(v) => setProtocol2(v)}
                >
                <Option value='http://'>http://</Option>
                <Option value='https://'>https://</Option>
              </Select>
            }
            addonAfter='/sitemap.xml'
            value={domain2}
            onPaste={structureUrl2}
            onChange={(e) => setDomain2(e.target.value)}
          />
          {submitted && !isValid2 && (
            <Text type='danger'>Please enter valid domain name</Text>
          )}
        </center>
      </Modal>
    </>
  );
};

export default CompareSite;
