import { Collapse, Row, Col, Space, Card } from "antd";
import { PageDetails } from "./page-details";
import { ExportOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getPageData } from "../../../api/site-scraping";
import { useLocation } from "react-router-dom";
const { Panel } = Collapse;

export const CompareUrlDetails = () => {
  const [fetchingList, setFetchingList] = useState([]);
  const location = useLocation();
  const [panes, setPanes] = useState([]);
  useEffect(() => {
    
    setPanes(location.state.data);
  }, []);

 
  return (
    <>
      <Row className="text-left compare-container" gutter={[32, 32]}>
        {panes.map((pane, i) => (
          <Col key={pane.url + i} className="gutter-row text-center" span={11}>
            <Card>
             
                        <PageDetails data={pane.data} url={pane.url} />
                     
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
