import React from "react";
import { Navigate } from "react-router-dom";
import { LoginForm } from "../../pages/login";

const CheckToken = () => {
  let token = localStorage.getItem("auth-token");
  return token ? <Navigate to="/" /> : <LoginForm />;
};

export default CheckToken;
