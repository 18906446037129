import { globalFetch } from ".";
import { REQUEST_TYPE } from "../constants/api-constants";

export const getSitePages = (qs) => {
  return globalFetch("get-pages", REQUEST_TYPE.GET, "", qs);
};

export const compareSitePages = (qs) => {
  return globalFetch("compare-pages", REQUEST_TYPE.GET, "", qs);
};

export const getPageData = (qs) => {
  return globalFetch("get-page-data", REQUEST_TYPE.GET, "", qs);
};

export const getImageData = (images) => {
  return globalFetch("get-image-data", REQUEST_TYPE.POST, {images},"");
};

export const get404LinkData = (links) => {
  return globalFetch("get-404-link-data", REQUEST_TYPE.POST, {links},"");
};

export const getPageInsightsForExcel = (links) => {
  return globalFetch("scrap-insights", REQUEST_TYPE.POST, {links},"");
}

export const authorizeUser = (userData) => {
  return globalFetch("authorize", REQUEST_TYPE.POST, userData,"", false);
} 
