import { Collapse, Row, Col, Space, Card } from "antd";
import { PageDetails } from "./page-details";
import { ExportOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getPageData } from "../../../api/site-scraping";
import { useLocation } from "react-router-dom";
const { Panel } = Collapse;

export const CompareDetails = () => {
  const [fetchingList, setFetchingList] = useState([]);
  const location = useLocation();
  const [panes, setPanes] = useState([]);
  useEffect(() => {
    setPanes(location.state.data);
  }, []);

  const onExpandLink = (url, paneIndex) => {
    if (fetchingList.includes(url)) return;
    const tempPanes = [...panes];

    if (tempPanes[paneIndex]["link_data"][url]) return;
    setFetchingList((oldList) => [...oldList, url]);
    getPageData({ url })
      .then((res) => {
        setFetchingList((oldList) => {
          const temp = [...oldList];
          temp.splice(url, 1);
          return temp;
        });
        if (res.data) {
          tempPanes[paneIndex]["link_data"][url] = res.data;
        }
      })
      .catch((e) => {
        setFetchingList((oldList) => {
          const temp = [...oldList];
          temp.splice(url, 1);
          return temp;
        });
      });
  };
  return (
    <>
      <Row className="text-left compare-container" gutter={[32, 32]}>
        {panes.map((pane, i) => (
          <Col key={pane.url + i} className="gutter-row text-center" span={11}>
            <Card>
              <Space direction="vertical" size={"middle"}>
                <h3>{pane.title}</h3>
                <div className="">
                  <a target="_blank" href={pane.url} rel="noreferrer">
                    <ExportOutlined /> {pane.url}
                  </a>
                </div>
                <span>
                  Total Pages : <strong>{pane.links.length}</strong>
                </span>
              </Space>
              <Collapse
                defaultActiveKey={["1"]}
                ghost
                className="page-url-collapse"
              >
                {pane.links.map((item) => (
                  <Panel
                    collapsible={fetchingList.includes(item) && "disabled"}
                    header={
                      <h4 onClick={() => onExpandLink(item, i)}>
                        <span>{item}</span>{" "}
                        {fetchingList.includes(item) && (
                          <Loading3QuartersOutlined spin />
                        )}
                      </h4>
                    }
                    key={item}
                  >
                    {fetchingList.includes(item) ? (
                      <p>
                        Fetching page data <Loading3QuartersOutlined spin />
                      </p>
                    ) : (
                      pane.link_data[item] && (
                        <PageDetails data={pane.link_data[item]} url={item} />
                      )
                    )}
                  </Panel>
                ))}
              </Collapse>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
