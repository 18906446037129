import { Row, Col, Table, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { getImageData } from "../../../api/site-scraping";
import { Loading3QuartersOutlined } from "@ant-design/icons";
const { Column } = Table;
const { Panel } = Collapse;
const images = [
  {
    title: "Digital Marketing Company BT Web Group White Logo",
    alt: "Digital Marketing Company BT Web Group White Logo",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-eDt8wVq1/w:1500/h:1500/q:mauto/f:avif/https://btwebgroup.com/wp-content/uploads/2020/12/BT-Logo-New4_white_transparent.png",
    size: 0,
  },
  {
    title: "Digital Marketing Company BT Web Group Logo",
    alt: "Digital Marketing Company BT Web Group Logo",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-HZggTciL/w:1500/h:1500/q:mauto/f:avif/https://btwebgroup.com/wp-content/uploads/2022/05/BT-Logo-New4_transparent-BT_dropshadow.png",
    size: 0,
  },
  {
    alt: "Website Health",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-B-1_qxzH/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Website-Health-2.png",
    size: 0,
  },
  {
    alt: "Digital Marketing Agency In Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-pX_wVuBf/w:1600/h:500/q:mauto/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/BTWB-2.png",
    size: 0,
  },
  {
    alt: "Website Audit tool",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-_QMT0gNB/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Website-Audit-2.png",
    size: 0,
  },
  {
    alt: "Internet Marketing Lexington,KY",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-bEHa1cOC/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Marketing-Book-2.png",
    size: 0,
  },
  {
    alt: "Digital Marketing Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-vjU4MEND/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Content-Marketing-2.png",
    size: 0,
  },
  {
    alt: "Social Media Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-a1zwE9yZ/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Social-Media-2.png",
    size: 0,
  },
  {
    alt: "Marketing Consultation Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-FQHqOzGV/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Marketing-Consultation-2.png",
    size: 0,
  },
  {
    alt: "PPC Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-q_Z4XhsW/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/PPC-2.png",
    size: 0,
  },
  {
    alt: "Internet Marketing Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-InThLS-h/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/BTMP-Slide-2.png",
    size: 0,
  },
  {
    alt: "Video Marketing Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-4vdoTLcE/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Video-Marketing-2.png",
    size: 0,
  },
  {
    alt: "Website Health",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-B-1_qxzH/w:1600/h:500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/Website-Health-2.png",
    size: 0,
  },
  {
    alt: "Digital Marketing Agency In Lexington",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-pX_wVuBf/w:1600/h:500/q:mauto/f:avif/https://btwebgroup.com/wp-content/uploads/2022/02/BTWB-2.png",
    size: 0,
  },
  {
    title: "BT Web Group Logo",
    alt: "BT Web Group Logo",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-NzCcb3z0/w:150/h:150/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2020/11/BT-Logo-New4_transparent-BT_dropshadow.png",
    size: 0,
  },
  {
    title: "bbb-logo and trophy of BT Web Group",
    alt: "bbb-logo and trophy of BT Web Group",
    src: "https://mlixwcmjqi5c.i.optimole.com/LFBGHKE-zAaFn2lJ/w:1800/h:1500/q:eco/f:avif/https://btwebgroup.com/wp-content/uploads/2021/10/bbb-logo-and-trophy-of-BT-Web-Group-1.png",
    size: 0,
  },
];

export const PageDetails = ({ data, url }) => {
  const eachWordCount = Object.entries(data.each_word_count).sort((a, b) =>
    a[1] > b[1] ? -1 : 1
  );

  console.log(data);
  return (
    <>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">URL</h3>
          <span>{url}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Canonical</h3>
          <span>{data.canonical_url}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Title</h3>
          <span>{data.title}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Title Character Count</h3>
          <span>{data.title_char_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Description</h3>
          <span>{data.description ? data.description : "-"}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Description Character Count</h3>
          <span>{data.description_char_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Meta Robots</h3>
          <span>{data.meta_robots}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Keyword Count</h3>
          <span>{data.total_word_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Character Count</h3>
          <span>{data.character_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Status Code</h3>
          <span>200</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Indexable</h3>
          <span>{data.indexable}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Page Followable</h3>
          <span>{data.followable}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Total Headings</h3>
          <span>{data.headings_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Total Images</h3>
          <span>{data.images_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Total Links</h3>
          <span>{data.link_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Internal Links</h3>
          <span>{data.internal_links_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">External Links</h3>
          <span>{data.external_links_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">No Follow Links</h3>
          <span>{data.nofollow_links_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Valid Links</h3>
          <span>{data.valid_links_count}</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <h3 className="text-primary">Empty Links</h3>
          <span>{data.empty_links_count}</span>
        </Col>
      </Row>
      <br />
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  All Keywords - {data.total_word_count}
                </h3>
              }
            >
              <Table dataSource={eachWordCount} pagination={false}>
                <Column
                  title="Keyword"
                  key="keyword"
                  render={(i) => <span>{i[0]}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Count"
                  key="count"
                  render={(i) => <span>{i[1]}</span>}
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  Headings - {data.headings_count}
                </h3>
              }
            >
              <Table dataSource={data.heading} pagination={false}>
                <Column
                  title="Heading Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Heading Type"
                  key="heading-type"
                  dataIndex="type"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Text"
                  key="heading-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">Images - {data.images_count}</h3>
              }
            >
              <Table dataSource={data.images} pagination={false}>
                <Column
                  title="Image Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Image Title"
                  key="image-title"
                  dataIndex="title"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Image Alt Text"
                  key="alt-text"
                  dataIndex="alt"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Image size (KB)"
                  key="image-size"
                  dataIndex="size"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">All Links - {data.link_count}</h3>
              }
            >
              <Table dataSource={data.links} pagination={false}>
                <Column
                  title="Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  Internal Links - {data.internal_links_count}
                </h3>
              }
            >
              <Table dataSource={data.internal_links} pagination={false}>
                <Column
                  title="Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  External Links - {data.external_links_count}
                </h3>
              }
            >
              <Table dataSource={data.external_links} pagination={false}>
                <Column
                  title="Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  No Follow Links - {data.nofollow_links_count}
                </h3>
              }
            >
              <Table dataSource={data.nofollow_links} pagination={false}>
                <Column
                  title="Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  Valid Links - {data.valid_links_count}
                </h3>
              }
            >
              <Table dataSource={data.valid_links} pagination={false}>
                <Column
                  title="Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  404 Links - {data["404_links_count"]}
                </h3>
              }
            >
              <Table dataSource={data["404_links"]} pagination={false}>
                <Column
                  title="404 Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="404 Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="404 Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  Server Error Links - {data.server_error_links_count}
                </h3>
              }
            >
              <Table dataSource={data["server_error_links"]} pagination={false}>
                <Column
                  title="Server Error Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Server Error Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Server Error Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  Redirect Links - {data.redirect_links_count}
                </h3>
              }
            >
              <Table dataSource={data["redirect_links"]} pagination={false}>
                <Column
                  title="Server Error Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Server Error Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Server Error Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <Row className="text-left" gutter={[32, 32]}>
        <Col className="gutter-row" span={24}>
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="heading-collapse"
          >
            <Panel
              header={
                <h3 className="text-primary">
                  Empty Links - {data.nofollow_links_count}
                </h3>
              }
            >
              <Table dataSource={data.empty_links} pagination={false}>
                <Column
                  title="Link Number"
                  key="heading-number"
                  render={(i, j, index) => <span>{index + 1}</span>}
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link URL"
                  key="link-url"
                  dataIndex="url"
                  ellipsis={{ showTitle: true }}
                />
                <Column
                  title="Link Anchor Text"
                  key="link-text"
                  dataIndex="text"
                  ellipsis={{ showTitle: true }}
                />
              </Table>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};
