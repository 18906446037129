import "./App.css";
import "antd/dist/antd.min.css";
import { Routes, Route } from "react-router-dom";
import { Homepage } from "./pages/homepage";
import { CompareDetails } from "./pages/homepage/components/compare-details";
import { CompareUrlDetails } from "./pages/homepage/components/compare-url-details";
import { LoginForm } from "./pages/login";
import CheckToken from "./wrappers/auth/CheckToken";
import Protected from "./wrappers/auth/Protected";

function App() {
  return (
    <div className='App'>
      <h2>X Ray SEO</h2>
      <Routes>
        <Route element={<CheckToken />}>
          <Route path="/login" element={<LoginForm />} />
        </Route>
        <Route element={<Protected />}>
          <Route path='/' element={<Homepage />} />
          <Route path='/compare' element={<CompareDetails />} />
          <Route path='/compare-url' element={<CompareUrlDetails />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
