import { Modal, Input, Select, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
const { Option } = Select;
const { Text } = Typography;
const AddSite = ({ visible, setVisible, loading, handleOk }) => {
  const [domain, setDomain] = useState("");
  const [protocol, setProtocol] = useState("https://");
  const [submitted, setSubmitted] = useState("");

  useEffect(() => {
    if (!visible) {
      setSubmitted(false);
    }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
  };
  const reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
const urlReg=/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  const isValid = useMemo(() => {
    return reg.test(domain);
  }, [domain]);
  const structureUrl = (e) => {
    try {
      setTimeout(() => {

      
  if( e.target.value.match(urlReg)){
    const url = new URL(e.target.value);
    setProtocol(`${url.protocol}//`);
    setDomain(url.hostname);
  }
      }, 0);
    } catch (e) {
      // console.log(e);
    }
  };
  return (
    <>
      <Modal
        centered
        title="Enter Domain Name"
        visible={visible}
        onOk={() => {
          setSubmitted(true);
          if (isValid) {
            handleOk(protocol + domain);
          }
        }}
        confirmLoading={loading}
        onCancel={handleCancel}
      >
        <center>
          {/* <label>Enter Domain Name</label> */}
          <Input
            addonBefore={
              <Select
                value={protocol}
                className="select-before"
                onChange={(v) => setProtocol(v)}
              >
                <Option value="http://">http://</Option>
                <Option value="https://">https://</Option>
              </Select>
            }
            addonAfter="/sitemap.xml"
            value={domain}
            onPaste={structureUrl}
            onChange={(e) => setDomain(e.target.value)}
          />
          {submitted && !isValid && (
            <Text type="danger">Please enter valid domain name</Text>
          )}
        </center>
      </Modal>
    </>
  );
};

export default AddSite;
