export const API_URL = process.env.REACT_APP_API_URL;
export const REQUEST_TYPE = {
  POST: "post",
  GET: "get",
  put: "put",
  delete: "delete",
};
export const newTitleMapping = {
  "title": "Title",
  "metaTitle": "Meta Title",
  "metaDescription": "Meta Description",
  "tags": "Tags",
  "url": "Url",
  "slug": "Slug",
  "featuredImage": "FeaturedImage",
  "parentPage": "ParentPage",
};